.login-container{
    margin: 15px;
}
.login-section{
    display: flex;
    flex-direction: column;
}
.login-section p {
    margin: 10px 0px;
}
.login-section input{
    padding: 15px 10px;
    background-color: #F0F0F0;
    border: none;
    border-radius: 10px;
}
.upload-file-btns{
    justify-content: space-between;
}

.bildirge-btn{
    border: none;
    padding: 15px 10px;
    border-radius: 10px;
    width: 45%;
}

.bildirge-input {
    padding: 10px;
    background-color: #F0F0F0;
    border: none;
    border-radius: 10px;
    margin: 1rem 0rem;
    width: 100%;
}

.drag-drop-input {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s ease;
  }
  
  .drag-drop-input.drag-over {
    border-color: #1890ff;
  }
  
  .file-input-label {
    color: #1890ff;
    cursor: pointer;
  }
  
  .file-input-label:hover {
    text-decoration: underline;
  }
  