.edit-modal-container{
    text-align: center;
}
.edit-modal-container p{
    font-size: 17px;
    font-weight: 500;
    margin: 1rem;
}
.edit-modal-buttons{
    display: flex;
    justify-content: space-around;
}