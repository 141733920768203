
.register-form-section form{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}
.register-form-section label{
    width: 47%;
    margin-left: 1rem;
    margin: 1rem 0rem 0rem 1rem;
}
.register-form-section input, select{
    width: 100%;
    padding: 8px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 10px;
    margin-top: 0.5rem;
}
.register-form-section button{
    background:     linear-gradient(to right, #EB0004, #9B0101);    ;
    width: 95.5%;
    margin: 3rem 0rem 0rem 0rem;
}
.register-form-section button:hover{
    opacity: 0.8;
    background:  linear-gradient(to right, #EB0004, #9B0101);
}
@media (max-width: 560px) {
   
    .register-form-section label{
        width: 95%;
    margin-left: 0px;

    }
    .register-form-section input{
        width: 100%;
    }
    
  }
  