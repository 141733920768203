p{
  margin: 0;
}
.button{
  background: linear-gradient(to left, #EB0004, #9B0101);
  border-radius: 5px;
  text-align: center;
  color: white;
  border: none;
  padding: 10px;
  margin-top: 1rem;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #333;
  color: white;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  transition: width 0.3s ease;
}
.sidebar .side-list {
  list-style-type: none;
 }
 .sidebar .side-list li a {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}
.sidebar .side-list li a p {
  margin-left: 10px;
}
.side-list{
  padding-inline-start: 0px;
}
@media (max-width: 768px) {
  .sidebar {
    width: 40px; /* Mobil cihazlarda sidebar genişliği */
  }

  .sidebar .side-list li a {
    justify-content: center;
  }
  .sidebar .side-list {
    list-style-type: none;
   }

  .sidebar .side-list li a i {
    margin: 20px 0px;
  }

  .sidebar .side-list li a p {
    display: none; /* Metni gizle */
  }

  .content {
    margin-left: 80px; /* Mobilde sidebar açıkken içerik için boşluk */
  }
}

.content {
  margin-left: 250px;
  transition: margin-left 0.3s ease;
  width: calc(100% -  300px);
}

@media (max-width: 768px) {
  .content {
    margin-left: 20px;
    width: 100%; /* Mobil cihazlarda içeriğin başlangıç kenar boşluğu */
  }
  .side-,list {
    padding-inline-start: 0px !important;
  }
  
}


