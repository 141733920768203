body {
  margin: 0;
  font-family: 'Poppins'
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
  white-space: nowrap;
  color: white;
}