/* AddAuditorModal.css */
.auditor-form-group {
    margin-bottom: 15px;
  }
  
  .auditor-form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .auditor-form-group input , select{
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box; /* input genişliğinin padding'i içerdiğinden emin ol */
  }
  
  button[type="submit"] {
    background-color: #4CAF50; /* Yeşil */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
  }
  
  button[type="submit"]:hover {
    background-color: #45a049;
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    border-radius: 5px;
  }
  
  /* Modal'ı ekranın ortasında hizala */
  .ant-modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .ant-modal-content {
    width: 100%;
    max-width: 600px; /* Modal genişliğini sınırla */
  }
  