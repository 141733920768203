.home-container{
    padding: 15px;
}
.home-subtitile{
    color: #959595;
    font-weight: 700;
    padding: 0.8rem 0rem;
}
.info-container{
    display: flex;
    flex-wrap: wrap;
}
.info-container p{
font-size: 12px;
}
.info-card{
    width: 50%;
    padding:  5px 4px 0px 0px;
    margin-top: 3p;
}
.info-card p,a{
    color: #898989;
    margin: 2px 0px;
}
.card-text{
    background-color: #F0F0F0;
    padding: 10px 5px;
    border-radius: 5px;
}
.info-text{
    margin: 0 !important;
}
