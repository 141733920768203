.admin-home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .admin-page-body {
    margin-top: 2rem;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .admin-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
  }
  .admin-buttons button {
    background-color: black;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 20px;
    margin: 5px;
    flex: 1 1 auto;
    min-width: 120px;
  }
  .admin-cards-section {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 2rem;
  }
  
  .admin-info-card {
    background-color: #f5f5f5;
    border-radius: 25px;
    width: 25%;
    margin: 10px;
    padding: 50px 20px;
  }
  
  .info-numbner {
    color: #000;
    font-feature-settings: "liga" off;
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }
  .info-text {
    color: #000;
    font-feature-settings: "liga" off;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
  .selected-link{
    color: white;
    font-weight: 500;
  }
  
  .admin-card {
    display: flex;
    padding: 30px 50px;
    color: white;
    width: 30%;
    border-radius: 15px;
    margin: 0.5rem;
    align-items: center;
  }
  .admin-card-hasar{
      display: flex;
      padding: 30px 50px;
      color: white;
      width: 20%;
      border-radius: 15px;
      margin: 0.5rem; 
  }
  .admin-card-text {
    width: 100%;

  }
  .admin-cards{
      display: flex;
      flex-wrap: wrap;
      margin-top: 4rem;
  }
  .-buttons{
    display: flex;
    align-items: center;
  }
  .excel-button{
    border: none;
    padding: 15px;
    border-radius: 10px;
    color: #037D00;
    font-weight: bold;
    margin-left: 10px;
    height: 50%;
  }
  .admin-home-container{
    margin: 0rem 1rem;
  }
  .delete-btn {
    background-color: red;
    border: none;
    padding: 10px;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    margin-top: 1rem;
  }
  .auditor-info{
    background-color: #EEE;
    margin-top: 5px;
    padding: 5px 10px;
    border-radius: 10px;
  }
  .count {
    font-size: 25px;
  }
  .table-detail {
    border: 1px solid;
    cursor: pointer;
    border-radius: 10px;
    padding: 5px 10px;
    text-align: center;
  }
  
  @media (max-width: 1024px) {
    .admin-info-card {
      width: 45%;
    }
    .admin-buttons button {
      min-width: calc(100% - 20px);
    }
    .admin-card {
      width: 40%;
    }
    .admin-card-hasar{
      width: 40%;
    }
  }
  
  @media (max-width: 762px) {
    .admin-info-card {
      width: 90%;
    }
    .admin-card {
      padding: 10px;
      width: 100%;
    }
    .admin-card img{
      max-width: 20%;
    }
    .admin-card-hasar{
      padding: 10px;
      width: 100%;
    }
    .excel-button {
      height: 100%;
    }
    
  }
  