.navbar-container{
    display: flex;
    background: linear-gradient(to right, #EB0004, #9B0101);
    align-items: center;
    justify-content: center;
    padding: 30px 10px;
}
.navbar-titles{
    margin-left: 1rem;
}
.title {
    font-weight: 700;
    color: white;
    font-size: 19px;
    white-space: nowrap;
  }
.subtitle{
    color: white;
    font-size: 17px;
    font-weight: 400;
}
.logo{
width: 6%;
}

@media (max-width: 720px) {
    .navbar-container{
        padding: 20px 10px;
    }
    .logo {
        width: 25%;
    }
    .title {
        font-size: 14px;
      }
    .subtitle{
        font-size: 11px;
    }
  }